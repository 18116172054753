import { createTheme, responsiveFontSizes } from "@mui/material";

const t = createTheme({
  palette: {
    text: {
      primary: "#EEE",
      secondary: "#EEE",
    },
  },
  typography: {
    fontFamily:
      "IBM Plex Sans,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
    h1: {
      color: "#FFF",
      fontWeight: "bold",
      fontSize: 36,
      letterSpacing: 1.5,
    },
    h2: {
      color: "#FFF",
      fontWeight: "bold",
      fontSize: 32,
      letterSpacing: 1.25,
    },
    h3: {
      color: "#FFF",
      fontWeight: "bold",
      fontSize: 28,
      letterSpacing: 1.25,
    },
    subtitle1: {
      color: "#FFF",
      fontSize: 20,
    },
    subtitle2: {
      color: "#FFF",
      fontSize: 18,
    },
    h4: {
      color: "#FFF",
      fontWeight: "bold",
      fontSize: 16,
      letterSpacing: 1.25,
    },
    body1: {
      color: "#888",
    },
  },
});

export const theme = responsiveFontSizes(t);
export const spacing = {
  small: { xs: 1, sm: 1, md: 2, lg: 3, xl: 3 },
  medium: { xs: 2, sm: 2, md: 3, lg: 4, xl: 5 },
  large: { xs: 3, sm: 4, md: 5, lg: 6, xl: 8 },
};

export const maxWidth = 1000;
