/** @jsxImportSource @emotion/react */
import { makeStyles } from "../styles/makeStyles";
import { FC } from "react";
import { Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { spacing } from "../styles/theme";

const styles = makeStyles({
  box: {
    border: "double 2px transparent",
    borderRadius: 10,
    backgroundImage:
      "linear-gradient(#111, #111), radial-gradient(circle at top left, #f00,#3020ff)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    width: "calc(100% - 10px)",
    height: "100%",
    cursor: "pointer",
    position: "relative",
  },
  close: {
    position: "absolute",
    top: 8,
    right: 8,
    color: "white",
    fontSize: 24,
    fontWeight: "bold",
  },
});

type P = {
  onClick?: () => void;
  showClose: boolean;
};

export const RainbowBox: FC<P> = (props) => (
  <Box css={styles.box} sx={{ mb: 1 }} onClick={props.onClick}>
    {props.showClose && <Close css={styles.close} />}

    <Box sx={{ p: spacing.medium }}>{props.children}</Box>
  </Box>
);
