import { Page } from "../../utils/Page";
import { ProjectList } from "../../features/projects/ProjectList";
import { Typography } from "@mui/material";
import React from "react";

export const MyProjects = () => (
  <Page>
    <Typography variant="h2">My Projects</Typography>
    <ProjectList currentOnly={false} />
  </Page>
);
