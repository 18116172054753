/** @jsxImportSource @emotion/react */
import { makeStyles } from "../../styles/makeStyles";
import { Box, Typography } from "@mui/material";
import { spacing } from "../../styles/theme";
import { useNavigate } from "react-router-dom";

const styles = makeStyles({
  footerWrapper: {
    width: "100vw",
  },
  link: {
    cursor: "pointer",
  },
});
export const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box css={styles.footerWrapper}>
      <Box sx={{ px: spacing.large, pt: spacing.large }}>
        <hr />
        <Box sx={{ py: spacing.small, px: spacing.medium }}>
          <Typography
            variant="body1"
            onClick={() => navigate("/imprint")}
            css={styles.link}
          >
            Imprint
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
