/** @jsxImportSource @emotion/react */

import {
  Box,
  ImageList,
  ImageListItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { makeStyles } from "../../styles/makeStyles";
import { currentProjects } from "./projectData";
import { ProjectItem } from "./ProjectItem";

const styles = makeStyles({
  standaloneBoxWrapper: {
    marginTop: 16,
  },
});

type ProjectsProps = {
  currentOnly: boolean;
};

export const ProjectList = (props: ProjectsProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState<number | null>(null);
  const projects = useMemo(
    () =>
      props.currentOnly
        ? currentProjects.filter((p) => p.current)
        : currentProjects,
    [props.currentOnly]
  );
  return (
    <Box>
      {open === null ? (
        <ImageList rowHeight="auto" cols={isMobile ? 1 : 2}>
          {projects.map((p, i) => {
            return (
              <ImageListItem cols={open === i ? 3 : 1}>
                <ProjectItem
                  isLarge={false}
                  project={p}
                  setOpen={setOpen}
                  i={i}
                  currentOnly={props.currentOnly}
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      ) : (
        <Box css={styles.standaloneBoxWrapper}>
          <ProjectItem
            isLarge={true}
            project={projects[open]}
            setOpen={setOpen}
            i={open}
            currentOnly={props.currentOnly}
          />
        </Box>
      )}
    </Box>
  );
};
