export type Project = {
  title: string;
  client: string;
  description: string;
  fromTo: string;
  techStack: string[];
  longDescription: string;
  features: string[];
  current: boolean;
};

export const currentProjects: Project[] = [
  {
    title: "Audi Digital Events Platform.",
    client: "Audi AG/BB onsite GmbH",
    description: "A multi tenant event platform.",
    fromTo: "2021",
    features: [
      "IT security according to industry standards",
      "High availability",
      "Multi tenant structure",
      "High customizable",
      "Detailed KPI Reportings",
    ],
    longDescription:
      "The contract for extending the existing CLX-frontend for this project originated from Ping Pong Labs GmbH. The contract for general consulting regarding this project originated from BB onsite GmbH.\n This project is based on the existing Central Launch Experience application. It lifts that application to a new level by allowing onClick creation of separate events as well as their detailed configuration and customization. It features a theming engine, Social Login, all of the features of the CLX such as Video Conferencing and a lot more smaller features.\n I especially enjoyed the challenge of making the application highly customizable without letting the overall user journey and code quality suffering from this much added complexity.",
    techStack: [
      "React.js",
      "Typescript",
      "React native",
      "Jitsi",
      "AWS Infrastructure",
    ],
    current: true,
  },

  {
    title: "Audi digital Central Launch Experience",
    client: "Audi AG",
    description:
      "A large-scale event platform supporting Live Video Conferencing.",
    fromTo: "2020",
    features: [
      "IT security according to industry standards",
      "High availability",
      "Live Video Conferencing",
      "Live Streaming",
      "Detailed KPI Reportings",
    ],
    longDescription:
      "The contract for developing the frontend and video conferencing solution for this project originated from Ping Pong Labs GmbH.\n This project started very short term in mid 2020, when the Corona Pandemic hit and Audi needed a digital way of training their retailers.\n From the beginning on the Video conferencing was a core requirement of this project. During the development of this project I was able to grow substantial knowledge on both hosting and customizing jitsi, the chosen open source video conferencing solution.\n Additionally the platform features On-Demand Videos, Live Streaming (using AWS MediaLive), a KPI Dashboard, Exams, Surveys, Gamification and many more smaller features. Both the very short time frame to implement those features and the video conferencing solution were special challenges of this project.\n The CLX is currently successfully running in its third major version with users from more than 40 countries.",

    techStack: [
      "React.js",
      "Typescript",
      "React native",
      "Jitsi",
      "AWS Infrastructure",
    ],
    current: true,
  },
  {
    title: "Audi eTwin Cup 2020/21",
    client: "Audi AG",
    description:
      "The Audi eTwin Cup was the first digital transformation of the traditional Twin Cup. ",
    fromTo: "2021",
    longDescription:
      "The contract for developing the frontend for this project originated from Ping Pong Labs GmbH, they took over the implementation of the backend.\n The core of this application was a functionality where participants had to solve different tasks, which were implemented in the frontend. The jury could follow their progress live in the application and rate the participants. \n This core module was accompanied by other features like a News-Feed that could be managed by an Administrator from inside the platform, a history section, rankings of the different teams, onDemand Videos and live streams and many more.\n The exam section was a special challenge, as the various tasks had a very different format but for the sake of maintainability a common data structure for communication of the clients needed to be implemented. \n This project had it's clear focus on business logic in the frontend, so I loved it!",
    features: [
      "IT security according to industry standards",
      "High availability",
      "Detailed KPI Reportings",
    ],
    techStack: ["React.js", "Typescript", "AWS Infrastructure"],
    current: false,
  },
  {
    title: "Open Journal Systems/Open Monograph Press",
    client: "Deutsches Archäologisches Institut",
    description: "Various plugins and customizations for OJS and OMP by PKP.",
    fromTo: "2019 - 2021",
    features: [
      "Plugin Development for OMP/OJS",
      "Complicated Database Migrations",
      "Extension of the Lens-Viewer",
    ],
    longDescription:
      "This is a summary of various contracts originated by the German Archaeological Institute.\n Those projects included the development and conception of a Plugin for OJS and OMP to automatically generate DOIs at the DZB, the coordination of the development of a theming plugin, preparing and extending the OJS/OMP API for the idai.workbench, establishing a connection between the Lens-Viewer and OJS and many more small plugins/extensions. \n The integration with the various 3rd party applications was quite challenging, but sadly mainly because of the lack of documentation or possibilities to extend those in an organic way. A special challenge was extending those Applications while maintaining the possibility to upgrade them in the future and to minimize the effort to do so.\n Those projects certainly where a challenge, however I am quite sure I enjoy working on an application I have full control over much more. ",
    techStack: ["PHP", "Mustache", "OJS/OMP", "JS"],
    current: false,
  },
  {
    title: "idai.workbench",
    client: "Deutsches Archäologisches Institut",
    description:
      "A microservice based application for importing Journals to OJS and OMP.",
    fromTo: "2018 - 2020",
    features: [
      "Microservice Architecture",
      "Natural Language Processing",
      "Optical Character Recognition",
      "PDF generation using LaTeX",
    ],
    longDescription:
      "The contract for developing the idai.workbench was originated by the German Archaeological Institute.\n It included the backend implementation of the microservices and a basic frontend. The frontend used Vue.js for a prototype and due to limitations to Vue at this time React.js in the final version. The backend uses Python, a Message Queue based on Redis and a classical relational database.\n The scope of the application was to import journals and retro-digitalized books to OJS and OMP. It uses OCR and NLP to recognize Metadata, create Annotations and link keywords, places and persons to different directories such as a gazetteer. The journals were either imported as PDFs or Word Files. For importing the Word-journals a microservice to create design-compliant PDFs based on LaTeX was required. For this process some minor microservices such as basic PDF or image processing needed to be implemented as well. \n A special challenge was the microservice architecture, which was a requirement of the customer. This enabled them to connect different applications to the developed backend later on. ",
    techStack: ["Python", "React.js", "Vue.js"],
    current: false,
  },
  {
    title: "Orbis Latinus Online",
    client: "Ruprecht-Karls-University, Heidelberg",
    description:
      "A gazetteer providing a two-way search for latin place names.",
    fromTo: "2018",
    features: [
      "Intuitive UI",
      "Integration with a large preexisting database",
      "Manipulation of the data for certain users",
    ],
    longDescription:
      "This project was originated by Dr. Kilian Schultes from the Ruprecht-Karls-University.\n This project had the main goal of modernizing and extending an already existing jQuery frontend. The found locations should be shown on a map and the overall UI needed to be improved. Furthermore an admin area to maintain the large database needed to be added.\n ",
    techStack: ["jQuery", "PHP"],
    current: false,
  },
  {
    title: "Staff Administration",
    client: "Deutsches Archäologisches Institut",
    description: "A tool for administrating contracts.",
    fromTo: "2017",
    features: ["Securing confidential data"],
    longDescription:
      "This was my first software contract ever. It was originated by the German Archaeological Institute.\n This software is used by the client to administer their staffs contracts, create new contracts, get notifications of expiring ones, calculating the total employment time and more.\n Being my first project ever almost everything was a challenge for me. I learnt a lot and the client turned out to be really satisfied with the result. Designing and developing an application from the ground up was a very important experience for me. ",
    techStack: ["Laravel", "JS"],
    current: false,
  },
];
