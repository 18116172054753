/** @jsxImportSource @emotion/react */

import { Box, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { maxWidth, spacing } from "../../styles/theme";
import { makeStyles } from "../../styles/makeStyles";
// @ts-ignore
import Logo from "../../media/Logo.jpg";
type R = {
  path: string;
  name: string;
};
const routes: R[] = [
  {
    path: "/",
    name: "Home",
  },
  {
    path: "/projects",
    name: "My Projects",
  },
  {
    path: "/imprint",
    name: "Imprint",
  },
];

const styles = makeStyles({
  wrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  flex: {
    display: "flex",
    width: "100%",
    maxWidth: maxWidth,
    justifyContent: "space-between",
  },
  innerFlex: {
    alignItems: "center",
    display: "flex",
    height: "100%",
  },
  logo: {
    height: "100%",
  },
});

export const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Box css={styles.wrapper}>
      <Box css={styles.flex} sx={{ px: spacing.large, pt: spacing.large }}>
        <Box css={styles.innerFlex}>
          {routes.map((r) => {
            const isActive = location.pathname === r.path;
            return (
              <Box
                sx={{ mr: spacing.medium }}
                onClick={() => navigate(r.path)}
                css={{ cursor: "pointer" }}
              >
                <Typography
                  variant="body1"
                  css={{ color: isActive ? "white" : undefined }}
                >
                  {r.name}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Box css={styles.logo}>
          <img src={Logo} alt="NG" css={{ height: 40, width: "auto" }} />
        </Box>
      </Box>
    </Box>
  );
};
