/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "../styles/makeStyles";
import { maxWidth, spacing } from "../styles/theme";

const styles = makeStyles({
  wrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  page: {
    width: "100%",
    maxWidth: maxWidth,
  },
});

export const Page: FC = (props) => (
  <Box css={styles.wrapper}>
    <Box sx={{ p: spacing.large }} css={styles.page}>
      {props.children}
    </Box>
  </Box>
);
