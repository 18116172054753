import { Page } from "../../utils/Page";
import { Box, Typography } from "@mui/material";
import React from "react";
import { spacing } from "../../styles/theme";
import { ProjectList } from "../../features/projects/ProjectList";

export const MainPage = () => {
  return (
    <Page>
      <AboutMe />
      <Spacer />

      <Typography variant="h2">Current Projects</Typography>
      <ProjectList currentOnly />
    </Page>
  );
};

const Spacer = () => <Box sx={{ py: spacing.medium }} />;

const AboutMe = () => (
  <Box>
    <Typography variant="h1">Nemo Grippa</Typography>
    <Typography variant="subtitle1">
      Freelance Senior Fullstack Developer
    </Typography>
    <Box sx={{ py: spacing.small }}>
      <Typography variant="body1">
        Currently working on large scale frontend projects.
      </Typography>
    </Box>
  </Box>
);
