/** @jsxImportSource @emotion/react */
import React from "react";
import { makeStyles } from "./styles/makeStyles";

import { MainPage } from "./pages/main/MainPage";
import { Footer } from "./features/footer/Footer";
import { Routes, Route } from "react-router-dom";
import { Imprint } from "./pages/imprint/Imprint";
import { Navbar } from "./features/navbar/Navbar";
import { MyProjects } from "./pages/projects/MyProjects";

const styles = makeStyles({
  wrapper: {
    backgroundColor: "#111",
    width: "100vw",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  spreader: {
    height: "100%",
    flexGrow: 1,
  },
});

function App() {
  return (
    <div css={styles.wrapper}>
      <Navbar />
      <Routes>
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/projects" element={<MyProjects />} />
        <Route path="/" element={<MainPage />} />
      </Routes>
      <div css={styles.spreader} />
      <Footer />
    </div>
  );
}

export default App;
