import { Project } from "./projectData";
import { RainbowBox } from "../../utils/RainbowBox";
import { Box, Typography } from "@mui/material";
import { spacing } from "../../styles/theme";
import React from "react";

type P = {
  isLarge: boolean;
  project: Project;
  setOpen: (next: number | null) => void;
  i: number;
  currentOnly: boolean;
};

export const ProjectItem = (props: P) => (
  <RainbowBox
    onClick={() => props.setOpen(props.isLarge ? null : props.i)}
    showClose={props.isLarge}
  >
    <Box sx={{ pr: props.isLarge ? spacing.small : undefined }}>
      <Typography variant="h3">{props.project.title}</Typography>
    </Box>
    <Typography variant="subtitle2" sx={{ pt: 1 }}>
      {props.project.client}, {props.project.fromTo}{" "}
      {!props.currentOnly && props.project.current && "(ongoing)"}
    </Typography>
    <Typography variant="body1" sx={{ pt: 1 }}>
      {props.project.description}
    </Typography>
    {props.isLarge && (
      <Box>
        <Box sx={{ pt: spacing.small }}>
          {props.project.longDescription?.split("\n").map((p) => (
            <Typography variant="body1" sx={{ pt: 1 }}>
              {p}
            </Typography>
          ))}
        </Box>
        <Box sx={{ pt: spacing.small }}>
          <Typography variant="h4">Core Requirements</Typography>
          {props.project.features?.map((f) => (
            <Box sx={{ pl: 1 }}>
              <Typography variant="body1">- {f}</Typography>
            </Box>
          ))}
        </Box>
        <Box sx={{ pt: spacing.small }}>
          <Typography variant="h4">Tech Stack</Typography>
          {props.project.techStack?.map((t) => (
            <Box sx={{ pl: 1 }}>
              <Typography variant="body1">- {t}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    )}
  </RainbowBox>
);
