import { Box, Link, Typography } from "@mui/material";
import { Page } from "../../utils/Page";
import { spacing } from "../../styles/theme";

export const Imprint = () => {
  return (
    <Page>
      <Typography variant="h2">Imprint</Typography>
      <Box sx={{ py: spacing.medium }}>
        <Typography variant="body1">
          Nemo Grippa <br />
          Berliner Straße 159 <br />
          10715 Berlin
          <br />
          Germany
        </Typography>
      </Box>
      <Box sx={{ py: spacing.medium }}>
        <Typography variant="body1">
          +49 152 2899 3251 <br />
          <Link
            href="mailto:ngrippa.dev@gmail.com"
            color="inherit"
            underline="none"
          >
            ngrippa.dev@gmail.com
          </Link>
          <br />
          VAT: DE 313 583 215
        </Typography>
      </Box>
    </Page>
  );
};
