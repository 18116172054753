import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { theme } from "./styles/theme";
import { CssBaseline, GlobalStyles, ThemeProvider } from "@mui/material";
import { HashRouter } from "react-router-dom";
import { makeStyles } from "./styles/makeStyles";

const styles = makeStyles({
  track: {
    "::-webkit-scrollbar": {
      width: 3,
    },

    /* Track */
    "::-webkit-scrollbar-track": {
      background: "linear-gradient(#f00, #3020ff)",
    },

    /* Handle */
    "::-webkit-scrollbar-thumb": {
      background: "transparent",
      boxShadow: "0px 0px 0px 100000vh black",
    },
    body: {
      overflowX: "hidden",
      margin: 0,
    },
  },
});
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <HashRouter>
      <CssBaseline />
      <GlobalStyles styles={styles.track} />
      <App />
    </HashRouter>
  </ThemeProvider>,
  document.getElementById("root")
);
